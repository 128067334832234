<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="title"
        :mask-closable="false"
        :closable="false"
        :transfer="true">
        <div class="loading-wrap">
            <Progress :percent="percent" />
        </div>
        <div slot="footer" class="modal-footer">
            <Poptip
                class="poptip"
                confirm
                placement="top-end"
                title="关闭后，将不再通知本次上传结果"
                cancel-text="再等等"
                ok-text="确认关闭"
                @on-ok="close">
                <Button type="primary">关闭，在后台上传</Button>
            </Poptip>
        </div>
    </Modal> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            percent: {
                default: 0
            },
            title: {
                default: '安全检测中'
            }
        },
        data () {
            return {
                modal: false
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
            },
        },
        methods: {
            close() {
                this.$emit('cancel')
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.loading-wrap {
    .loading-gif {
        display: block;
        margin: 0 auto;
    }
}
.poptip {
    margin-right: 8px;
    text-align: left;
}
</style>