<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="添加素材"
        :width="1100"
        :mask-closable="false"
        @on-visible-change="handleVisibleChange"
    >
        <Row class="search-bar">
            <Col span="16">
                <RadioGroup class="radio-group-button" v-if="is_org" v-model="bucket" type="button" @on-change="handleSpace">
                    <!-- <Radio label="user">个人空间</Radio> -->
                    <Radio label="org">企业空间</Radio>
                </RadioGroup>
                <Dropdown v-if="is_org && bucket === 'org'" class="dropdown dropdown-structure" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                    <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList()" />
                    <Button type="text">
                        组织架构
                        <div v-if="nodeList.length" class="tree line-overflow">
                            <span>:</span>
                            <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                <span v-if="index !== nodeList.length - 1">,</span>
                            </span>
                        </div>
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown class="dropdown dropdown-type" trigger="click"  placement="bottom-start" >
                    <Icon v-if="type" class="close" type="ios-close-circle" @click="clearTypeList" />
                    <Button type="text">
                        类型<span v-if="type">:{{ type.name }}</span>
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <DropdownItem v-for="item in typeList" :key="item.material_type" class="type line-overflow" @click.native="handleTypeList(item)">{{ item.text._ }}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <Dropdown class="dropdown dropdown-label" trigger="click"  placement="bottom-start" >
                    <Icon v-if="label" class="close" type="ios-close-circle" @click="clearLabelList" />
                    <Button type="text">
                        标签<span v-if="label">:{{ label.name }}</span>
                        <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <DropdownMenu slot="list">
                        <load-more v-if="labelList.length !== 0"  :onRefresh="handleReachBottom" :disabled="isLoaded" height="165px">
                            <Tooltip class="tooltip" :content="item.name" theme="light" placement="top-start" transfer v-for="(item, index) in labelList" :key="index" :delay="1000">
                                <DropdownItem class="label line-overflow" @click.native="handleLabelList(item)">{{ item.name }}</DropdownItem>
                            </Tooltip>
                        </load-more>
                    </DropdownMenu>
                </Dropdown>
            </Col>
            <Col span="8" class="clearfix">
                <Button v-if="uploadable" class="float-right" type="primary" @click="handleUpload">上传素材</Button>
                <Input v-model="query" class="float-right margin-right" clearable placeholder="输入素材名称搜索" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                    <Icon type="ios-search pointer" slot="suffix" @click="searchNew" />
                </Input>
            </Col>
        </Row>
        <no-data v-show="list.length === 0" text="暂无素材"></no-data>
        <CheckboxGroup v-show="list.length > 0" class="content-block" v-model="checkAllGroup" @on-change="checkAllGroupChange">
            <div :class="['content-item', item.selected ? 'target': '']" v-for="item in list" :key="item.rowid">
                <div class="img-wrap">
                    <img classs="image-center" :src="`/cym/${item.uri}/thumbnail`">
                </div>
                <Checkbox class="checkbox" :label="item.rowid" @click.native.stop="handleCheckbox($event, item)"><span>&nbsp;</span></Checkbox>
                <div class="name line-overflow">{{ item.name }}</div>
            </div>
            <Spin fix size="large" v-if="loading"></Spin>
        </CheckboxGroup>
        <Page
            v-show="list.length > 0"
            class="page"
            :total="total"
            :current="current"
            :page-size="pageSize"
            show-elevator
            @on-change="nextPage" />

        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
        <upload-modal
            ref="upload"
            class="content-upload"
            :show="uploadModalShow"
            :bucket_token="bucket_token"
            @confirm="handleUploadConfirm"
            @cancel="handleUploadCancel"
            @show="handleUploadModal"
        ></upload-modal>
    </Modal>  
</template>
<script>
    import uploadModal from 'views/components/upload'
    import noData from '@/views/components/noData'
    import loadMore from '@/views/components/loadMore'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            uploadModal,
            noData,
            loadMore
        },
        props: {
            show: {
                default: false
            },
            cellWidth: {
                default: null
            },
            cellHeight: {
                default: null
            },
            is_org: {
                default: ''
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                bucket: this.is_org?'org':'user',
                query: '',
                uploadModalShow: false,
                current: 1,
                pageSize: 14,
                total: 0,
                list: [],
                checkAllGroup: [],
                checkAllResult: [],
                itemInfo: {},
                nodeList: [],
                treeList: [],
                data: [],
                allowList: ['image', 'template', 'data_template'],
                label: null,
                labelList: [],
                labelPageSize: 10,
                labelCurrent: 1,
                isLoaded: false,
                type: null,
                typeList: []
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens[this.bucket]
            },
            tree_data() {
                return [this.$store.state.up_tree]
            },
            uploadable() {
                if (this.bucket === 'org' && this.$store.state.rolesList.indexOf('content') === -1) return false
                return true
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.data = this.handleRecursive(this.tree_data)
                    this.getLabelList()
                    this.getData()
                }
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.query = ''
                this.current = 1
                this.list = []
                this.total = 0
                this.checkAllGroup = []
                this.checkAllResult = []
                this.itemInfo = {}
                this.data = []
                this.nodeList = []
                this.treeList = []
                this.bucket = this.is_org?'org':'user'
                this.labelCurrent = 1
                this.labelList = []
                this.label = null
                this.type = null
                this.$emit('cancel')
            },
            submit() {
                if (this.checkAllResult.length === 0) {
                    return this.$Message.error('请选择素材')
                }
                this.$emit('confirm', this.checkAllResult)
                this.cancel()
            },
            clearLabelList() {
                this.current = 1
                this.label = ''
                this.getData()
            },
            handleLabelList(label) {
                this.current = 1
                this.label = label
                this.getData()
            },
            getLabelList() {
                let params = {
                    bucket_token: this.bucket_token,
                    start: (this.labelCurrent - 1) * this.labelPageSize,
                    count: this.labelPageSize
                }
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.labelCurrent += 1
                        this.labelList.push(...data)
                    }
                    if (this.labelList.length == total_count) {
                        this.isLoaded = true
                    } else {
                        this.isLoaded = false
                    }
                })
            },
            async handleReachBottom(done) {
                await this.getLabelList()
                done()
            },
            getTypeList() {
                this.$api.get('materials/type_list').then(({ data }) => {
                    this.typeList = data.filter((item) => item.material_type !== 'audio')
                })
            },
            clearTypeList() {
                this.type = null
                this.getData()
            },
            handleTypeList(type) {
                this.type = {
                    name: type.text._,
                    value: type.material_type,
                }
                this.getData()
            },
            handleUpload() {
                if (this.$refs['upload'].defaultList.length > 0) {
                    this.$refs['upload'].loadingModalShow = true
                    return
                }
                this.$el.querySelector('.content-upload').getElementsByTagName('input')[0].click()
                this.$refs['upload'].init = true
            },
            handleUploadModal() {
                this.uploadModalShow = true
            },
            handleUploadConfirm() {
                this.handleUploadCancel()
                this.nodeList = []
                this.current = 1
                this.getData()
            },
            handleUploadCancel() {
                this.uploadModalShow = false
            },
            handleSpace() {
                this.clearLabelList()
                this.labelList = []
                this.labelCurrent = 1
                this.getLabelList()
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            getData() {
                let params = {
                        query: this.query,
                        type_list: "template,data_template,video,image",
                        start: (this.current - 1) * this.pageSize,
                        count: this.pageSize,
                        add_to_plan_flag: true
                    },
                    len = this.nodeList.length,
                    store = JSON.parse(JSON.stringify(this.checkAllGroup));
                if (this.type) {
                    params.type_list = this.type.value
                }
                if (this.label) {
                    params.label_id = this.label.rowid
                }
                if (this.is_org && this.bucket === 'org') {
                    if (len) {
                        let bucket_token_list = []
                        this.nodeList.map((item) => {
                            bucket_token_list.push(item.bucket_token)
                        })
                        params.bucket_token_list = JSON.stringify(bucket_token_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.data, 'bucket_token')
                        params.bucket_token_list = JSON.stringify(this.treeList)
                    }
                } else {
                    params.bucket_token = this.$store.state.default_bucket_tokens.user
                }
                this.checkAllGroup = []
                this.loading = true
                this.$api.get('materials/search', { params }).then(({ data, total_count, page })=>{
                    if (page === this.current) {
                        this.list = data.map((item) => {
                            if (store.indexOf(item.rowid) !== -1) {
                                item.selected = true
                            } else {
                                item.selected = false
                            }
                            return item
                        })
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{
                    this.checkAllGroup = store
                })
            },
            handleCheckbox(e, item) {
                this.itemInfo = item
            },
            checkAllGroupChange(e) {
                let len = this.list.length,
                    selected = false

                if (e.indexOf(this.itemInfo.rowid) === -1) {
                    this.checkAllResult.map((resultItem, index)=>{
                        if (this.itemInfo.rowid === resultItem.rowid) {
                            selected = false
                            this.checkAllResult.splice(index, 1)
                        }
                    })
                } else {
                    let { rowid, name, uri, material_info, play_settings } = this.itemInfo,
                        params = {};
                    params.rowid = rowid
                    params.name = name
                    params.uri = uri
                    params.width = material_info.resolution[0]
                    params.height = material_info.resolution[1]
                    params.media_type = material_info.media_type
                    params.resolution = material_info.resolution
                    params.play_settings = play_settings

                    if (this.allowList.indexOf(material_info.media_type) > -1) {
                        //分辨率均小于显示区域时，保持原分辨率，居中显示
                        if (material_info.resolution[0] <= this.cellWidth && material_info.resolution[1] <= this.cellWidth) {
                            params.y = this.cellHeight / 2 - material_info.resolution[1] / 2
                            params.x = this.cellWidth / 2 - material_info.resolution[0] / 2
                        } else {
                            /*
                             *长宽任一分辨率大于显示区域长宽时，最小长/宽放大至显示区域长/宽，居中显示
                             *长宽均大于显示区域长宽时，最小长/宽放大至与显示区域值相应长/宽相等，居中显示
                            **/

                            let scale = 1
                            if (material_info.resolution[0] / this.cellWidth <= material_info.resolution[1] / this.cellHeight) {
                                scale = this.cellWidth / material_info.resolution[0]
                            } else {
                                scale = this.cellHeight / material_info.resolution[1]
                            }

                            params.width = parseInt(material_info.resolution[0] * scale)
                            params.height = parseInt(material_info.resolution[1] * scale)

                            params.x = (this.cellWidth - params.width) / 2
                            params.y = (this.cellHeight - params.height) / 2
                        }
                    }

                    if (play_settings[0] === 'times') {
                        params.times = 1
                    } else {
                        params.duration = 10
                    }
                    this.checkAllResult.push(params)
                    selected = true
                }
                for(let i = 0; i < len; i++) {
                    if (this.itemInfo.rowid === this.list[i].rowid) {
                        this.list[i].selected = selected
                    }
                }
            }
        },
        mounted() {
            this.getTypeList()
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';
.radio-group-button {
    position: relative;
    margin-right: 15px;
    &::after {
        content: '\200B';
        position: absolute;
        top: 50%;
        right: -10px;
        width: 1px;
        height: 15px;
        background: #b4b9bf;
        transform: translateY(-50%);
    }
}
.dropdown {
    position: relative;
    vertical-align: bottom;
    .close {
        display: none;
    }
    .label-mag {
        cursor: pointer;
        padding: 7px 0 7px 4px;
        font-size: 14px;
    }
    &:hover {
        .close {
            display: block;
            position: absolute;
            right: 0px;
            top: 0px;
        }
    }
    .tree {
        display: inline-block;
        max-width: 100px;
        vertical-align: bottom;
    }
}
.dropdown-label {
    .tooltip {
        display: block;
        width: 100%;
        .label {
            max-width: 179px;
        }
    }
}
.content-block {
    margin: 0 auto;
    width: 966px;
    .content-item {
        position: relative;
        display: inline-block;
        margin: 0 10px 10px 0;
        width: 128px;
        .checkbox {
            position: absolute;
            top: 0;
            left: 0;
            margin-right: 0;
            width: 100%;
            height: 100%;
        }
        .img-wrap {
            position: relative;
            width: 128px;
            height: 128px;
            background: #7f7f7f;
            box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-height: 100%;
                max-width: 100%;
            }
        }
        .name {
            width: 100%;
            font-size: 16px;
            text-align: center;
        }
    }
    .target {
        .img-wrap {
            border: 1px solid @primary-color;
        }
    }
}
@deep: ~">>>";
.checkbox @{deep} .ivu-checkbox {
    position: absolute;
    right: 8px;
    top: 8px;
}
</style>