export default {
    data() {
        return {
            timer: null,
            percent: 0,
            block_list: [],
            switch: false,
            loadingModalShow: false,
            failedModalShow: false
        }
    },
    methods: {
        check_upload_status(params) {
            function checkStatus(params) {
                return this.$api.get('materials/check_upload_status', { params }).then(({ block_list, rowid_list }) => {
                        this.block_list.push(...block_list)
                        let len_block = this.block_list.length,
                            _this = this;
                    if (rowid_list.length === JSON.parse(params.rowid_list).length) {
                        clearInterval(this.timer)
                        this.percent = 100
                        setTimeout(function() {
                           if (_this.cancel) {
                                _this.$emit('confirm')
                                _this.cancel()
                            } else {
                                _this.handleLoadingModalCancel()
                            }
                        }, 500)
                    }
                    if (len_block && this.loadingModalShow) {
                        clearInterval(this.timer)
                        this.failedModalShow = true
                    }
                }).catch(() => {})

            }
            return checkStatus.bind(this, params)
        }
    }
}